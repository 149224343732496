<template>
  <div class="alert bg-light-info d-flex flex-column flex-sm-row w-100 p-5 mt-5">
    <div class="d-flex flex-column pe-0 pe-sm-10 ps-5">
      <h4 class="fw-semibold">Accès <strong>Pro</strong>.</h4>
      <span>Passez en mode Pro et bénéficier de toutes les fonctionnalités du site.</span>
      <div class="d-flex flex-column flex-sm-row d-grid gap-2">

        <stripe-buy-button class="mt-3"
                           buy-button-id="buy_btn_1NSIGGFekYzBq4jwm4QZnNAc"
                           :customer-email="email"
                           publishable-key="pk_live_51ILvF7FekYzBq4jw5oMJ6QFw21yc18uOhHAulSr9vXv8xn7DUS3jta80SYBr9rCoimjfKp7DbHUkiucfq2Sjbsgr00YQuT7MUA"
        >
        </stripe-buy-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    email: {
      type: String,
      required: true,
    },
  },
}
</script>

<style scoped>

</style>