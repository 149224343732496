var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-xl-12" }, [
        _c("div", { staticClass: "card card-flush" }, [
          _c("div", { staticClass: "card-header" }, [
            _c("div", { staticClass: "card-title fs-3 fw-bold" }, [
              _c(
                "div",
                { staticClass: "d-flex flex-column mt-4" },
                [
                  _vm.premium === "false" ? [_vm._m(0)] : _vm._e(),
                  _vm._v(
                    "\n\n              " +
                      _vm._s(
                        _vm.$t(
                          "message.availabilities.title",
                          _vm.locale_request
                        )
                      ) +
                      "\n            "
                  ),
                ],
                2
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "card-body p-9 pt-0" },
            [
              _c(
                "div",
                { staticClass: "mb-10" },
                [
                  _c(
                    "p",
                    { staticClass: "fs-4 fw-semibold text-gray-600 py-4 m-0" },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.$t(
                              "message.availabilities.intro",
                              _vm.locale_request
                            )
                          ) +
                          "\n            "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.isAvailabilitiesEmpty > 0
                    ? [
                        _c(
                          "div",
                          {
                            staticClass:
                              "notice d-flex bg-light-primary rounded border- p-6 mb-10",
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "svg-icon svg-icon-2tx svg-icon-primary me-4",
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    attrs: {
                                      width: "24",
                                      height: "24",
                                      viewBox: "0 0 24 24",
                                      fill: "none",
                                      xmlns: "http://www.w3.org/2000/svg",
                                    },
                                  },
                                  [
                                    _c("rect", {
                                      attrs: {
                                        opacity: "0.3",
                                        x: "2",
                                        y: "2",
                                        width: "20",
                                        height: "20",
                                        rx: "10",
                                        fill: "currentColor",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("rect", {
                                      attrs: {
                                        x: "11",
                                        y: "17",
                                        width: "7",
                                        height: "2",
                                        rx: "1",
                                        transform: "rotate(-90 11 17)",
                                        fill: "currentColor",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("rect", {
                                      attrs: {
                                        x: "11",
                                        y: "9",
                                        width: "2",
                                        height: "2",
                                        rx: "1",
                                        transform: "rotate(-90 11 9)",
                                        fill: "currentColor",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "d-flex flex-stack flex-grow-1" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "mb-3 mb-md-0 fw-semibold" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "fs-6 text-gray-700" },
                                      [
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(
                                              _vm.$t(
                                                "message.availabilities.notice",
                                                _vm.locale_request
                                              )
                                            ) +
                                            "\n                      "
                                        ),
                                        _c("br"),
                                        _vm._v(" "),
                                        _c(
                                          "a",
                                          {
                                            staticClass: "fw-bold fs-6",
                                            attrs: {
                                              href: _vm.link,
                                              target: "_blank",
                                              id: "kt_clipboard_4",
                                            },
                                          },
                                          [_vm._v(_vm._s(_vm.link))]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    : [
                        _c(
                          "div",
                          {
                            staticClass:
                              "notice d-flex flex-column flex-sm-row bg-light-primary rounded border- p-6 mb-10",
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "svg-icon svg-icon-2tx svg-icon-primary me-4",
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    attrs: {
                                      width: "24",
                                      height: "24",
                                      viewBox: "0 0 24 24",
                                      fill: "none",
                                      xmlns: "http://www.w3.org/2000/svg",
                                    },
                                  },
                                  [
                                    _c("rect", {
                                      attrs: {
                                        opacity: "0.3",
                                        x: "2",
                                        y: "2",
                                        width: "20",
                                        height: "20",
                                        rx: "10",
                                        fill: "currentColor",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("rect", {
                                      attrs: {
                                        x: "11",
                                        y: "17",
                                        width: "7",
                                        height: "2",
                                        rx: "1",
                                        transform: "rotate(-90 11 17)",
                                        fill: "currentColor",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("rect", {
                                      attrs: {
                                        x: "11",
                                        y: "9",
                                        width: "2",
                                        height: "2",
                                        rx: "1",
                                        transform: "rotate(-90 11 9)",
                                        fill: "currentColor",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", {}, [
                              _c(
                                "div",
                                { staticClass: "mb-3 mb-md-0 fw-semibold" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "fs-6 text-gray-700" },
                                    [
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(
                                            _vm.$t(
                                              "message.availabilities.notice_future",
                                              _vm.locale_request
                                            )
                                          ) +
                                          "\n                      "
                                      ),
                                      _c("br"),
                                      _vm._v(" "),
                                      _c(
                                        "a",
                                        {
                                          staticClass: "fw-bold fs-6",
                                          attrs: {
                                            href: _vm.link,
                                            target: "_blank",
                                            id: "kt_clipboard_4",
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.link))]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        ),
                      ],
                ],
                2
              ),
              _vm._v(" "),
              [
                _c(
                  "div",
                  { staticClass: "form-wrapper" },
                  [
                    _c("FormulateForm", {
                      attrs: { "keep-model-data": true },
                      on: { submit: _vm.submitHandler },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ isLoading }) {
                            return [
                              _c("FormulateInput", {
                                staticClass: "mt-10",
                                attrs: {
                                  type: "group",
                                  name: "availabilities",
                                  repeatable: true,
                                  minimum: 1,
                                  "add-label": _vm.$t(
                                    "message.availabilities.availability.buttonRepeat",
                                    _vm.locale_request
                                  ),
                                  "label-class": "form-label fs-6 fw-bold mb-3",
                                  value: _vm.formDatas,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ index }) {
                                        return [
                                          _c("CollapsableGroupItem", {
                                            attrs: {
                                              context: index,
                                              "show-index":
                                                _vm.availabilities.length <= 1
                                                  ? 0
                                                  : _vm.showIndex,
                                              availabilities:
                                                _vm.availabilities[index] ||
                                                false,
                                            },
                                            on: {
                                              open: function ($event) {
                                                _vm.showIndex = $event
                                              },
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                                model: {
                                  value: _vm.availabilities,
                                  callback: function ($$v) {
                                    _vm.availabilities = $$v
                                  },
                                  expression: "availabilities",
                                },
                              }),
                              _vm._v(" "),
                              _vm.premium === "false"
                                ? [
                                    _c("upgradePro", {
                                      attrs: { email: _vm.email },
                                    }),
                                  ]
                                : [
                                    _c("FormulateInput", {
                                      attrs: {
                                        type: "submit",
                                        disabled: isLoading,
                                        label: isLoading
                                          ? "Loading..."
                                          : _vm.$t(
                                              "message.buttons.save",
                                              _vm.locale_request
                                            ),
                                      },
                                    }),
                                  ],
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.formDatas,
                        callback: function ($$v) {
                          _vm.formDatas = $$v
                        },
                        expression: "formDatas",
                      },
                    }),
                  ],
                  1
                ),
              ],
            ],
            2
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "span",
      {
        staticClass:
          "badge badge-light-info flex-shrink-0 align-self-start py-3 px-4 fs-7 mb-2",
      },
      [
        _c("i", { staticClass: "fa-solid fa-lock text-info fs-8 me-2" }),
        _vm._v(" Pro"),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }