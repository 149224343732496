var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "alert bg-light-info d-flex flex-column flex-sm-row w-100 p-5 mt-5",
    },
    [
      _c("div", { staticClass: "d-flex flex-column pe-0 pe-sm-10 ps-5" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("span", [
          _vm._v(
            "Passez en mode Pro et bénéficier de toutes les fonctionnalités du site."
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "d-flex flex-column flex-sm-row d-grid gap-2" },
          [
            _c("stripe-buy-button", {
              staticClass: "mt-3",
              attrs: {
                "buy-button-id": "buy_btn_1NSIGGFekYzBq4jwm4QZnNAc",
                "customer-email": _vm.email,
                "publishable-key":
                  "pk_live_51ILvF7FekYzBq4jw5oMJ6QFw21yc18uOhHAulSr9vXv8xn7DUS3jta80SYBr9rCoimjfKp7DbHUkiucfq2Sjbsgr00YQuT7MUA",
              },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h4", { staticClass: "fw-semibold" }, [
      _vm._v("Accès "),
      _c("strong", [_vm._v("Pro")]),
      _vm._v("."),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }