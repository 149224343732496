var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "group-item",
      attrs: { "data-is-open": _vm.context === _vm.showIndex },
    },
    [
      _c(
        "div",
        { staticClass: "group-item-title", on: { click: _vm.toggle } },
        [
          _vm.availabilities &&
          (_vm.availabilities.first || _vm.availabilities.last)
            ? _c("span", [
                _vm._v(
                  _vm._s(_vm.availabilities.first) +
                    " " +
                    _vm._s(_vm.availabilities.last)
                ),
              ])
            : _c(
                "span",
                { staticClass: "form-label fs-4 fw-bold mb-3 cursor-pointer" },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "message.availabilities.availability.title",
                        _vm.locale_request
                      )
                    ) +
                      " n° " +
                      _vm._s(_vm.context + 1) +
                      " "
                  ),
                ]
              ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.context === _vm.showIndex,
              expression: "context === showIndex",
            },
          ],
          staticClass: "group-item-body",
        },
        [
          _c("div", { staticClass: "row mt-10" }, [
            _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-body" }, [
                  _c("div", { staticClass: "row" }, [
                    _vm.availabilities.endDate < _vm.getMinDate()
                      ? _c("div", [_vm._m(0)])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-md-6 mb-5" },
                      [
                        _c("FormulateInput", {
                          attrs: {
                            type: "date",
                            name: "startDate",
                            label: _vm.$t(
                              "message.availabilities.availability.forms.startDate.label",
                              _vm.locale_request
                            ),
                            "help-class": "form-text",
                            "label-class": "form-label fs-6 fw-bold mb-3",
                            "input-class": "form-control form-control-solid",
                            validation: "required",
                            min: _vm.getMinDate(),
                            "validation-messages": {
                              required: _vm.$t(
                                "message.availabilities.availability.required",
                                _vm.locale_request
                              ),
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-md-6 mb-5" },
                      [
                        _c("FormulateInput", {
                          attrs: {
                            type: "date",
                            name: "endDate",
                            label: _vm.$t(
                              "message.availabilities.availability.forms.endDate.label",
                              _vm.locale_request
                            ),
                            "help-class": "form-text",
                            "label-class": "form-label fs-6 fw-bold mb-3",
                            "input-class": "form-control form-control-solid",
                            validation: "required",
                            min: _vm.availabilities.startDate,
                            "validation-messages": {
                              required: _vm.$t(
                                "message.availabilities.availability.required",
                                _vm.locale_request
                              ),
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-12 mb-5" },
                      [
                        _c("FormulateInput", {
                          attrs: {
                            type: "group",
                            name: "journey_top_five_locations",
                            label: _vm.$t(
                              "message.availabilities.availability.forms.cities.label",
                              _vm.locale_request
                            ),
                            "help-class": "form-text",
                            "label-class": "form-label fs-6 fw-bold mb-3",
                            "input-class": "form-control form-control-solid",
                            repeatable: true,
                            "add-label": _vm.$t(
                              "message.availabilities.availability.forms.cities.buttonRepeat",
                              _vm.locale_request
                            ),
                            minimum: 1,
                            limit: 5,
                            "validation-messages": {
                              required: _vm.$t(
                                "message.availabilities.availability.required",
                                _vm.locale_request
                              ),
                            },
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({ index }) {
                                return [
                                  _c("FormulateInput", {
                                    ref: "cityInput",
                                    attrs: {
                                      name: "city",
                                      label:
                                        _vm.$t(
                                          "message.availabilities.availability.forms.cities.city.label",
                                          _vm.locale_request
                                        ) +
                                        (index + 1),
                                      "help-class": "form-text",
                                      "label-class":
                                        "form-label fs-5 fw-bold mb-3",
                                      "input-class":
                                        "form-control form-control-solid",
                                      validation: "required",
                                      "validation-messages": {
                                        required: _vm.$t(
                                          "message.availabilities.availability.required",
                                          _vm.locale_request
                                        ),
                                      },
                                    },
                                  }),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-12 mb-5" },
                      [
                        _c("FormulateInput", {
                          attrs: {
                            label: _vm.$t(
                              "message.availabilities.availability.forms.journey_isTravelOutsideCities.label",
                              _vm.locale_request
                            ),
                            name: "journey_isTravelOutsideCities",
                            type: "select",
                            options: {
                              yes: _vm.$t(
                                "message.dropdown.yes",
                                _vm.locale_request
                              ),
                              no: _vm.$t(
                                "message.dropdown.no",
                                _vm.locale_request
                              ),
                              depend: _vm.$t(
                                "message.dropdown.depend",
                                _vm.locale_request
                              ),
                            },
                            "help-class": "form-text",
                            "label-class": "form-label fs-6 fw-bold mb-3",
                            "input-class": "form-select form-select-solid",
                            validation: "required",
                            "error-behavior": "submit",
                            "validation-messages": {
                              required: _vm.$t(
                                "message.availabilities.availability.required",
                                _vm.locale_request
                              ),
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.availabilities.journey_isTravelOutsideCities ===
                        "depend"
                          ? _c("FormulateInput", {
                              attrs: {
                                type: "textarea",
                                name: "journey_isTravelOutsideCities_depend",
                                label: _vm.$t(
                                  "message.availabilities.availability.forms.journey_isTravelOutsideCities_depend.label",
                                  _vm.locale_request
                                ),
                                "help-class": "form-text",
                                "label-class": "form-label fs-6 fw-bold mb-3",
                                "input-class":
                                  "form-control form-control-solid",
                                validation: "required",
                                "error-behavior": "submit",
                                "validation-messages": {
                                  required: _vm.$t(
                                    "message.availabilities.availability.required",
                                    _vm.locale_request
                                  ),
                                },
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-12 mb-5" }, [
                      _c("div", { staticClass: "card card-flush border" }, [
                        _c("div", { staticClass: "card-header" }, [
                          _c(
                            "div",
                            { staticClass: "card-title fs-4 fw-bold" },
                            [
                              _vm._v(
                                "\n                      " +
                                  _vm._s(
                                    _vm.$t(
                                      "message.availabilities.availability.forms.opportunity.mainTitle",
                                      _vm.locale_request
                                    )
                                  ) +
                                  "\n                    "
                              ),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "card-body pt-0" },
                          [
                            _c("FormulateInput", {
                              attrs: {
                                label: _vm.$t(
                                  "message.availabilities.availability.forms.opportunity.label",
                                  _vm.locale_request
                                ),
                                name: "contract",
                                placeholder: "Sélectionnez un type",
                                type: "select",
                                options: {
                                  job: _vm.$t(
                                    "message.dropdown.job",
                                    _vm.locale_request
                                  ),
                                  freelance: _vm.$t(
                                    "message.dropdown.freelance",
                                    _vm.locale_request
                                  ),
                                  collab: _vm.$t(
                                    "message.dropdown.collab",
                                    _vm.locale_request
                                  ),
                                },
                                "help-class": "form-text",
                                "label-class": "form-label fs-6 fw-bold mb-3",
                                "input-class": "form-select form-select-solid",
                                validation: "required",
                                "error-behavior": "submit",
                                "validation-messages": {
                                  required: _vm.$t(
                                    "message.availabilities.availability.required",
                                    _vm.locale_request
                                  ),
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                { staticClass: "col-md-6" },
                                [
                                  _vm.availabilities.contract === "job"
                                    ? _c("FormulateInput", {
                                        attrs: {
                                          type: "select",
                                          options: {
                                            1: _vm.$t(
                                              "message.dropdown.yes",
                                              _vm.locale_request
                                            ),
                                            0: _vm.$t(
                                              "message.dropdown.no",
                                              _vm.locale_request
                                            ),
                                            2: _vm.$t(
                                              "message.dropdown.remote",
                                              _vm.locale_request
                                            ),
                                          },
                                          name: "partnershipFullTime_isRemote",
                                          label: _vm.$t(
                                            "message.availabilities.availability.forms.opportunity.options.job.partnershipFullTime_isRemote.label",
                                            _vm.locale_request
                                          ),
                                          "label-class":
                                            "form-label fs-6 fw-bold mb-3",
                                          "input-class":
                                            "form-control form-control-solid",
                                          validation: "required",
                                          "error-behavior": "submit",
                                          "validation-messages": {
                                            required: _vm.$t(
                                              "message.availabilities.availability.required",
                                              _vm.locale_request
                                            ),
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-md-6" },
                                [
                                  _vm.availabilities.contract === "job"
                                    ? _c("FormulateInput", {
                                        attrs: {
                                          type: "select",
                                          options: {
                                            "<50k": "<50k",
                                            "50-60k": "50-60k",
                                            "60-70k": "60-70k",
                                            "70-80k": "70-80k",
                                            "80-90k": "80-90k",
                                            "90-100k": "90-100k",
                                            "100-110k": "100-110k",
                                            "110-120k": "110-120k",
                                            "120-130k": "120-130k",
                                            "130-140k": "130-140k",
                                            "140-150k": "140-150k",
                                            "150-170k": "150-170k",
                                            "170-190k": "170-190k",
                                            "190-220k": "190-220k",
                                            "220-250k": "220-250k",
                                            ">250k": ">250k",
                                          },
                                          name: "partnershipFullTime_salaryRange",
                                          label: _vm.$t(
                                            "message.availabilities.availability.forms.opportunity.options.job.partnershipFullTime_salaryRange.label",
                                            _vm.locale_request
                                          ),
                                          "label-class":
                                            "form-label fs-6 fw-bold mb-3",
                                          "input-class":
                                            "form-control form-control-solid",
                                          validation: "required",
                                          "error-behavior": "submit",
                                          "validation-messages": {
                                            required: _vm.$t(
                                              "message.availabilities.availability.required",
                                              _vm.locale_request
                                            ),
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                { staticClass: "col-md-6" },
                                [
                                  _vm.availabilities.contract === "freelance"
                                    ? _c("FormulateInput", {
                                        attrs: {
                                          type: "text",
                                          name: "freelance_minimumHourlyRate",
                                          label: _vm.$t(
                                            "message.availabilities.availability.forms.opportunity.options.freelance.freelance_minimumHourlyRate.label",
                                            _vm.locale_request
                                          ),
                                          "help-class": "form-text",
                                          "label-class":
                                            "form-label fs-6 fw-bold mb-3",
                                          "input-class":
                                            "form-control form-control-solid",
                                          validation: "required|number",
                                          "error-behavior": "submit",
                                          "validation-messages": {
                                            required: _vm.$t(
                                              "message.availabilities.availability.required",
                                              _vm.locale_request
                                            ),
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-md-6" },
                                [
                                  _vm.availabilities.contract === "freelance"
                                    ? _c("FormulateInput", {
                                        attrs: {
                                          type: "text",
                                          name: "freelance_minimumHoursContract",
                                          label: _vm.$t(
                                            "message.availabilities.availability.forms.opportunity.options.freelance.freelance_minimumHoursContract.label",
                                            _vm.locale_request
                                          ),
                                          "help-class": "form-text",
                                          "label-class":
                                            "form-label fs-6 fw-bold mb-3",
                                          "input-class":
                                            "form-control form-control-solid",
                                          validation: "required|number",
                                          "error-behavior": "submit",
                                          "validation-messages": {
                                            required: _vm.$t(
                                              "message.availabilities.availability.required",
                                              _vm.locale_request
                                            ),
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "row" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "col-md-12" },
                                  [
                                    _vm.availabilities.contract === "collab"
                                      ? _c("FormulateInput", {
                                          key: "collab",
                                          attrs: {
                                            type: "select",
                                            name: "collab_free_or_paid",
                                            options: {
                                              paid: _vm.$t(
                                                "message.dropdown.paid",
                                                _vm.locale_request
                                              ),
                                              free: _vm.$t(
                                                "message.dropdown.free",
                                                _vm.locale_request
                                              ),
                                            },
                                            label: _vm.$t(
                                              "message.availabilities.availability.forms.opportunity.options.collab.collab_free_or_paid.label",
                                              _vm.locale_request
                                            ),
                                            label:
                                              "Quel type de collaboration ?",
                                            "label-class":
                                              "form-label fs-6 fw-bold mb-3",
                                            "input-class":
                                              "form-control form-control-solid mb-3",
                                            validation: "required",
                                            "error-behavior": "submit",
                                            "validation-messages": {
                                              required: _vm.$t(
                                                "message.availabilities.availability.required",
                                                _vm.locale_request
                                              ),
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "col-md-12" },
                                  [
                                    _vm.availabilities.collab_free_or_paid ===
                                    "paid"
                                      ? _c("FormulateInput", {
                                          attrs: {
                                            type: "select",
                                            name: "collab_paid_is_display_price",
                                            options: {
                                              yes: _vm.$t(
                                                "message.dropdown.yes",
                                                _vm.locale_request
                                              ),
                                              no: _vm.$t(
                                                "message.dropdown.no",
                                                _vm.locale_request
                                              ),
                                            },
                                            label: _vm.$t(
                                              "message.availabilities.availability.forms.opportunity.options.collab.collab_paid_is_display_price.label",
                                              _vm.locale_request
                                            ),
                                            "label-class":
                                              "form-label fs-6 fw-bold mb-3",
                                            "input-class":
                                              "form-control form-control-solid mb-3",
                                            validation: "required",
                                            "error-behavior": "submit",
                                            "validation-messages": {
                                              required: _vm.$t(
                                                "message.availabilities.availability.required",
                                                _vm.locale_request
                                              ),
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _vm.availabilities
                                  .collab_paid_is_display_price === "yes"
                                  ? _c(
                                      "FormulateInput",
                                      {
                                        attrs: {
                                          type: "group",
                                          name: "collab_paid_prices",
                                          repeatable: true,
                                          minimum: 1,
                                          label: _vm.$t(
                                            "message.availabilities.availability.forms.opportunity.options.collab.prices.label",
                                            _vm.locale_request
                                          ),
                                          "add-label": _vm.$t(
                                            "message.availabilities.availability.forms.opportunity.options.collab.prices.buttonRepeat",
                                            _vm.locale_request
                                          ),
                                          validation: "required",
                                          "label-class":
                                            "form-label fs-6 fw-bold mb-3",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "collab_paid_prices mt-3",
                                          },
                                          [
                                            _c("div", { staticClass: "row" }, [
                                              _c(
                                                "div",
                                                { staticClass: "col-md-6" },
                                                [
                                                  _c("FormulateInput", {
                                                    attrs: {
                                                      type: "text",
                                                      name: "collab_paid_prices_title",
                                                      label: _vm.$t(
                                                        "message.availabilities.availability.forms.opportunity.options.collab.prices.collab_paid_prices_title.label",
                                                        _vm.locale_request
                                                      ),
                                                      placeholder: _vm.$t(
                                                        "message.availabilities.availability.forms.opportunity.options.collab.prices.collab_paid_prices_title.placeholder",
                                                        _vm.locale_request
                                                      ),
                                                      "label-class":
                                                        "form-label fs-6 fw-bold mb-3",
                                                      "input-class":
                                                        "form-control form-control-solid mb-3",
                                                      validation: "required",
                                                      "error-behavior":
                                                        "submit",
                                                      "validation-messages": {
                                                        required: _vm.$t(
                                                          "message.availabilities.availability.required",
                                                          _vm.locale_request
                                                        ),
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "col-md-6" },
                                                [
                                                  _c("FormulateInput", {
                                                    attrs: {
                                                      type: "text",
                                                      name: "collab_paid_prices_price",
                                                      label: _vm.$t(
                                                        "message.availabilities.availability.forms.opportunity.options.collab.prices.collab_paid_prices_price.label",
                                                        _vm.locale_request
                                                      ),
                                                      placeholder: _vm.$t(
                                                        "message.availabilities.availability.forms.opportunity.options.collab.prices.collab_paid_prices_price.placeholder",
                                                        _vm.locale_request
                                                      ),
                                                      "label-class":
                                                        "form-label fs-6 fw-bold mb-3",
                                                      "input-class":
                                                        "form-control form-control-solid mb-3",
                                                      validation: "required",
                                                      "error-behavior":
                                                        "submit",
                                                      "validation-messages": {
                                                        required: _vm.$t(
                                                          "message.availabilities.availability.required",
                                                          _vm.locale_request
                                                        ),
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-12 mb-5" },
                      [
                        _c("FormulateInput", {
                          attrs: {
                            type: "text",
                            name: "goalArtists",
                            label: _vm.$t(
                              "message.availabilities.availability.forms.goalArtists.label",
                              _vm.locale_request
                            ),
                            help: _vm.$t(
                              "message.availabilities.availability.forms.goalArtists.help",
                              _vm.locale_request
                            ),
                            "help-class": "form-text",
                            "label-class": "form-label fs-6 fw-bold mb-3",
                            "input-class": "form-control form-control-solid",
                            validation: "required",
                            "error-behavior": "submit",
                            "validation-messages": {
                              required: _vm.$t(
                                "message.availabilities.availability.required",
                                _vm.locale_request
                              ),
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-md-12 mb-5" },
                      [
                        _c("FormulateInput", {
                          attrs: {
                            type: "textarea",
                            name: "introduction",
                            label: _vm.$t(
                              "message.availabilities.availability.forms.introduction.label",
                              _vm.locale_request
                            ),
                            help: _vm.$t(
                              "message.availabilities.availability.forms.introduction.help",
                              _vm.locale_request
                            ),
                            "validation-name": "tweet",
                            "error-behavior": "live",
                            "label-class": "form-label fs-6 fw-bold mb-3",
                            "input-class": "form-control form-control-solid",
                            "help-class": "form-text",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "form-check form-switch form-check-custom form-check-solid",
                          },
                          [
                            _c("FormulateInput", {
                              attrs: {
                                type: "checkbox",
                                name: "is_active",
                                checked: "",
                                label: _vm.$t(
                                  "message.availabilities.availability.forms.is_active.label",
                                  _vm.locale_request
                                ),
                                "label-class": "form-label fs-6 fw-bold mb-0",
                                "input-class":
                                  "form-check-input mt-0 me-4 mb-0",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12" }, [
      _c(
        "div",
        {
          staticClass:
            "alert alert-dismissible bg-light-danger d-flex flex-column flex-sm-row p-5 mb-10",
        },
        [
          _c("div", { staticClass: "d-flex flex-column pe-0 pe-sm-10" }, [
            _c("h4", { staticClass: "fw-semibold" }, [
              _vm._v("Disponibilité expiré"),
            ]),
            _vm._v(" "),
            _c("span", [
              _vm._v(
                "Cette disponibilité a expiré car la date de fin que vous avez choisie est dépassée. Veuillez sélectionner une nouvelle date de fin pour maintenir votre disponibilité à jour"
              ),
            ]),
          ]),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }